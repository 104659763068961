<template>
  <div
    class="body"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div class="content">
      <div class="firstbox">
        <div style="margin-bottom: 20px">
          <div class="basic-infomation">
            <div class="title">基础信息</div>
          </div>
          <el-form
            :inline="true"
            :model="leftList"
            class="demo-form-inline"
            label-position="right"
            label-width="90px"
            ref="leftList"
          >
            <el-form-item label="模板名称:" prop="templateName"><span class="ww">{{ leftList.templateName }}</span>
            </el-form-item><br />
            <el-form-item label="计算方式:">
              <span class="ww">{{ leftList.billingType == 1 ? "重量段" : "首重续重" }}</span>
            </el-form-item><br />
            <el-form-item label="币种:">
              <span class="ww">{{ leftList.currency }}</span>
            </el-form-item><br />
            <el-form-item label="材积参数:">
              <span class="ww">{{ leftList.volumeParam }}</span>
            </el-form-item><br />
            <el-form-item label="运费折扣:">
              <span class="ww">{{ leftList.discount }} </span>   
              </el-form-item><br />
            <el-form-item label="其他费用:">
                <span class="ww">{{ leftList.isRegisteredFee == 1 ? "挂号费" : "" }}</span>
                <span class="ww">{{ leftList.isRegisteredFee == 1 ? "操作费" : "" }}</span>
                <span class="ww">{{ leftList.isRegisteredFee == 1 ? "附加费" : "" }}</span>
              </el-form-item><br />
              <el-form-item label="挂号费折扣:" v-if="leftList.isRegisteredFee == 1">
                <span class="ww">{{ leftList.isRegisteredFeeDiscount == 1 ? "参与折扣" : "不参与折扣" }}</span>
              </el-form-item><br />
          </el-form>
        </div>
        <div class="priceTips">
            <div class="freightTips">
                <span>运费</span>
                <span> = </span>
                <span class="bulge" v-if="leftList.billingType == 1">运费</span>
                <span class="bulge" v-if="leftList.billingType == 2">首重运费 + (包裹计费重 - 首重) / 续重单位重量 x 续重单价</span>
                <span v-if="leftList.isRegisteredFee == 1"> + <span class="bulge">挂号费</span></span>
                <span v-if="leftList.isHandlingFee == 1"> + <span class="bulge">操作费</span></span>
                <span v-if="leftList.isAdditionalFee == 1"> + <span class="bulge">附加费</span></span>
            </div>
            <div class="tipsItem">附加费 = 运费 x 附加费率</div>
            <div class="tipsItem">最终运费 = 运费 x 运费折扣 x (1 + 附加费率)</div>
            <div class="tipsItem">挂号费和操作费按每票计算</div>
        </div>
      </div>
      <div class="thirdbox">
        <div class="basic-infomation">
          <div class="title">运费规则</div>
        </div>
        <div class="table">
         <el-table
          height="100%"
          :data="tableDatas"
          :span-method="objectSpanMethod"
          style="width: 100%"
          border
          cell-style="text-align:center"
          header-cell-style="text-align:center;background:#f7f8fa"
          :show-overflow-tooltip="true"
        >  
          <el-table-column prop="areaName" label="国家/地区" width="width">
          </el-table-column>
          <el-table-column prop="toWeight" label="重量范围" width="width">
            <template slot-scope="scope">
                <span class="ww">{{ scope.row.fromWeight }}g - {{ scope.row.toWeight }}g</span>
            </template>
          </el-table-column>
          <el-table-column prop="fee" label="费用" width="width" v-if="leftList.billingType == 1">
              <template slot-scope="scope">
                <span class="ww">￥{{ scope.row.fee }} /kg</span>
            </template>
          </el-table-column>
          <el-table-column prop="firstWeight" label="首重/首重运费" width="width" v-if="leftList.billingType == 2">
          <template slot-scope="scope">
                <span class="ww">{{ scope.row.firstWeight }} g/￥ {{ scope.row.firstFee }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="continuedUnitWeight" label="续重单位重量/续重单价" width="width" v-if="leftList.billingType == 2">
          <template slot-scope="scope">
                <span class="ww">{{ scope.row.continuedUnitWeight }} g/￥ {{ scope.row.continuedUnitFee }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="registeredFee" label="挂号费" width="width" v-if="leftList.isRegisteredFee == 1">
              <template slot-scope="scope">
                <span class="ww">￥{{ scope.row.registeredFee }}/票</span>
            </template>
          </el-table-column>
          <el-table-column prop="handlingFee" label="操作费" width="width" v-if="leftList.isHandlingFee == 1">
              <template slot-scope="scope">
                <span class="ww">￥{{ scope.row.handlingFee }}/票</span>
            </template>
          </el-table-column>
          <el-table-column prop="additionalRate" label="附加费率" width="width" v-if="leftList.isAdditionalFee == 1">
              <template slot-scope="scope">
                <span class="ww">{{ scope.row.additionalRate }}%</span>
            </template>
          </el-table-column>
        </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdetail } from '@/api/freight';

export default {
  data() {
    return {
      leftList: {
        currency: "",
        billingType: 1,
        templateName: "",
        volumeParam: "",
        discount: "",
        isAdditionalFee: "",
        isHandlingFee: "",
        isRegisteredFee: "",
        isRegisteredFeeDiscount: "",
      },
      tableDataCountry:'',
      tableData:[],
      tableDatas:[],
      tableData1:[],
      leftList:{
          areaCodeList:'',
      },

    };
  },
  created() {
    this.getData();
  },
  watch:{
      $route(to){
        this.getData();
      }
  },
  methods: {
    //物流商页面初始化
    async getData() {
      let fetchData = []
      try {
        const { code, data, message } = await getdetail(this.$route.query.id);
        if ((Object.is(code), 200)) {
            this.leftList = data.data[0];
            fetchData = data.data[0].rulesList;
        } else {
          this.$message.error(message);
        }
      } catch (error) {}
      const tableData = []
      const mergeRule = {}
      fetchData.forEach(({ rulesItemList, areaName }) => {
          rulesItemList.forEach(item => {
              item.areaName = areaName
          })
          tableData.push(...rulesItemList);
          mergeRule[areaName] = rulesItemList.length;
      })
      this.tableDatas = tableData
    },

    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        
    //       if (columnIndex === 0) {
    //       if (rowIndex % 3 === 0) {
    //         return {
    //           rowspan: 2,
    //           colspan: 1
    //         };
    //       } else {
    //         return {
    //           rowspan: 0,
    //           colspan: 0
    //         };
    //       }
    //     }
    //   },
  },
};
</script>

<style lang='scss' scoped>
.firstbox {
  flex-direction: column;
  height: 100%;
  padding: 20px 20px;
  display: flex;
  background-color: #f2f4f7;
  width: 400px;
  border-right: 1px solid #e1e3e8;
}
.thirdbox {
  height: 100%;
  display: flex;
//   flex-direction: column;
  padding: 22px 20px;
  width: calc(100% - 403px);
}
.body {
  height: 100%;
  margin: 0 !important;
}
.title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  border-left: 2px solid #006eff;
  padding-left: 10px;
  line-height: 14px;
}
.content {
  height: 100%;
  display: flex;
}
.priceTips{
    font-size: 12px;
    box-sizing: border-box;
    color: #888;
    padding: 12px;
    background: rgba(0,110,255,.06);
    border: 1px solid rgba(0,110,255,.2);
}
.tipsItem{
    color: #888;
    line-height: 20px;
}
.bulge{
    color: #000;
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 2px;
    height: 20px;
}
.freightTips{
    // height: 30px;
}
.freightTips span{
    line-height: 30px;
    padding: 2px 4px;
}
.table{
    flex: 1;
    overflow: auto;
    padding: 20px 0 0 0;
}
.ww{
    font-size: 12px;
}
</style>